import dayjs from 'date'
import links from 'links'
import config from 'config'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePathname } from 'router'
import React, { useMemo } from 'react'
import { useMunicipalities, usePublicModules } from 'models/city'

import { Knob } from 'components/inputs'
import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { WidthContainer } from 'components/layout'
import { openSupportModal } from 'compositions/modals'

import LogoBottom from './components/LogoBottom/LogoBottom'
import MobileAppImage from './components/MobileAppImage/MobileAppImage'

import s from './Footer.scss'
// import { useCityConfig, useVersion } from 'compositions/Footer/util'


type FooterSection = {
  className?: string
  title?: string
}

const FooterSection: React.FC<FooterSection> = (props) => {
  const { children, className, title } = props

  const { isDesktop } = useDevice()

  return (
    <div className={className}>
      {
        title && (
          <Text
            className={isDesktop ? 'mb-16px' : 'mb-8px'}
            message={title}
            size="s10"
            color="white-70"
          />
        )
      }
      {children}
    </div>
  )
}

const FooterLinks = () => {
  const { modules } = usePublicModules()
  const { isRootDomain } = useMunicipalities()
  const isNn = config.subDomain === 'nn'

  const footerLinks = useMemo(() => {
    return [
      Boolean(config.cityName === 'Саров') && (
        {
          title: 'Сайт Администрации г. Сарова',
          toTab: 'https://adm-sarov.ru/',
        }
      ),
      Boolean(config.cityName === 'Волгодонск') && (
        {
          title: 'Сайт Администрации г. Волгодонска',
          toTab: 'https://volgodonskgorod.donland.ru/',
        }
      ),
      !isNn && ({
        title: 'Публичная оферта',
        to: links.privacy,
      }),
      {
        id: 'support',
        title: 'Поддержка пользователей',
        to: '',
        onClick: openSupportModal,
      },
      modules.some((module) => module.tag === 'STATS') && (
        {
          title: 'Статистика сайта',
          to: links.statistics,
        }
      ),
      (isNn && !isRootDomain) && (
        {
          title: 'Сайт «Умного региона»',
          href: 'https://smartregion.52gov.ru',
        }
      ),
    ].filter(Boolean)
  }, [ isNn, isRootDomain, modules ])

  return (
    <>
      <div>
        {
          footerLinks.map(({ title, to, href, id, toTab, onClick }, index) => (
            <Href id={id} className="pointer" key={index} href={href} to={to} toTab={toTab} onClick={onClick}>
              <Text
                className={cx('opacity-72', {
                  'mt-4px': index,
                })}
                message={title}
                size="s13-r"
                color="white"
              />
            </Href>
          ))
        }
      </div>
    </>
  )
}

const Footer = () => {
  const { isMobile, isTablet, isDesktop } = useDevice()
  const pathname = usePathname()
  const { modules } = usePublicModules()
  const isNn = true || config.subDomain === 'nn'

  const isHomePage = pathname === '/'
  const isImmortalsPage = pathname.includes(links.immortals.root)

  // TODO update it
  // const { cityConfig } = useCityConfig()
  // const { versionData } = useVersion()
  const iosLink = 'https://apple.co/3rwPLGF' // TODO get from settings
  const androidLink = 'https://play.google.com/store/apps/details?id=ru.rusatom.utilities.smartcity20'

  const footerClassName = cx(s.footer, {
    'bg-gladiator': !isImmortalsPage,
    'bg-schindler': isImmortalsPage,
    'py-24px': isMobile,
    'py-32px': isTablet,
    'pt-40px pb-24px': isDesktop,
  })
  const footerMargin = !isHomePage && (isMobile ? 'mt-48px' : isTablet ? 'mt-72px' : 'mt-96px')

  const mobileAppNode = modules.some((module) => module.tag === 'MOBILE_APP') && (
    <FooterSection
      className={cx('flex-1', {
        'mt-24px': isTablet,
      })}
      title="Мобильные приложения"
    >
      <Href toTab={iosLink}>
        <MobileAppImage type="ios" />
      </Href>
      <br />
      <Href toTab={androidLink}>
        <MobileAppImage className="mt-8px" type="android" />
      </Href>
    </FooterSection>
  )

  const versionNode = (
    <Text
      className={cx('opacity-72', {
        'mt-72px': isDesktop,
        'mt-96px': isTablet,
      })}
      message={`© ${dayjs().format('YYYY')} АО «Цифровые платформы и решения Умного Города» версия ${config.cityName === 'Волгодонск' ? '1.7 от 16.11.2021' : '2.0 от 21.07.2022'}`}
      size="s13-r"
      color="white"
      html
    />
  )

  const scrollTopNode = isImmortalsPage && (
    <WidthContainer>
      <div className="relative">
        <div
          className="absolute right-0 z-10"
          style={{
            top: isMobile ? '-52px' : isTablet ? '-56px' : '-64px',
          }}
        >
          <Knob
            icon="arrow/arrow-up_16"
            style="white"
            color="platoon"
            circle
            onClick={() => {
              try {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })
              }
              catch {
                window.scroll(0, 0)
              }
            }}
          />
        </div>
      </div>
    </WidthContainer>
  )

  if (isMobile) {
    return (
      <footer className={cx(footerClassName, footerMargin)}>
        {scrollTopNode}
        <WidthContainer>
          <div className="flex flex-col">
            <FooterSection className="flex-1" title={isNn ? null : 'Инициатор'}>
              <LogoBottom
                type="city"
              />
            </FooterSection>
            <FooterSection className="flex-1 mt-16px" title="Разработчик">
              <LogoBottom type="rir" />
            </FooterSection>
          </div>
          <FooterSection className="mt-24px mb-24px" title="Полезные ссылки">
            <FooterLinks />
          </FooterSection>
          {mobileAppNode}
        </WidthContainer>
      </footer>
    )
  }

  return (
    <footer className={cx(footerClassName, 'relative')}>
      {scrollTopNode}
      <WidthContainer
        className="flex"
      >
        <FooterSection className="flex-1" title={isNn ? null : 'Инициатор'}>
          <LogoBottom type="city" />
          {versionNode}
        </FooterSection>
        <FooterSection className="flex-1 ml-16px" title="Разработчик">
          <LogoBottom type="rir" />
        </FooterSection>
        <FooterSection className="flex-1 ml-16px" title="Полезные ссылки">
          <FooterLinks />
          {!isDesktop && mobileAppNode}
        </FooterSection>
        {isDesktop && mobileAppNode}
      </WidthContainer>
    </footer>
  )
}


export default Footer
