import React from 'react'
import cx from 'classnames'
import config from 'config'
import { useCityConfig, useMunicipalities } from 'models/city'

import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'

import s from './LogoBottom.scss'

import logoRir from './images/rir.svg'


type LogoBottomProps = {
  className?: string
  type: 'rir' | 'city'
}

const links = {
  'Волхов': 'https://adm.izh.ru/',
  'Пуровский район': 'https://www.puradm.ru/',
  'Сочи': 'https://sochi.ru/',
  'город Тула': 'https://www.tula.ru/',
  'Волгодонск': 'http://volgodonskgorod.ru/',
}

const LogoBottom: React.FC<LogoBottomProps> = (props) => {
  const { className, type } = props

  const { isRootDomain } = useMunicipalities()
  const isNn = config.subDomain === 'nn'

  const { cityConfig } = useCityConfig()

  if (type === 'rir') {
    return (
      <img className={cx(s.image, className)} src={logoRir} />
    )
  }

  const initiatorTitle = cityConfig?.designOptions?.initiatorTitle

  const prefix = __DEV__ ? '/proxy' : ''

  const logoImage = (isNn && !isRootDomain)
    ? `${config.storage}/common/logoWhite.svg`
    : cityConfig?.designOptions?.initiatorLogo
      ? prefix + cityConfig?.designOptions?.initiatorLogo
      : cityConfig?.logoSvg // TODO delete this case - legacy setting
        ? prefix + cityConfig?.logoSvg
        : config.isMultipleDomains
          ? `${config.storage.replace(`/cities`, '')}/-/80/cities/${config.subDomain}/logo.png`
          : `${config.storage}/common/logoWhite.svg`

  return (
    <Href className={cx(className, 'flex items-center')} toTab={links[config.cityName] ?? null}>
      <img className={s.image} src={logoImage} />
      {
        initiatorTitle && (
          <Text
            className="ml-8px"
            message={initiatorTitle}
            size="s13-r"
            color="white"
            html
          />
        )
      }
    </Href>
  )
}


export default LogoBottom
