import React from 'react'
import cx from 'classnames'

import s from './MobileAppImage.scss'

import iosImage from './images/ios.svg'
import androidImage from './images/android.svg'


type LogoBottomProps = {
  className?: string
  type: 'ios' | 'android'
}

const logo = {
  ios: iosImage,
  android: androidImage,
}

const MobileAppImage: React.FC<LogoBottomProps> = (props) => {
  const { className, type } = props

  return (
    <img className={cx(s.image, className)} src={logo[type]} />
  )
}


export default MobileAppImage
